/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-checkout .payment-method #stripe_cc .payment-method-title .label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; }
  .cs-checkout .payment-method #stripe_cc .payment-method-title .label img {
    display: inline-block; }

.cs-checkout .payment-method #stripe_cc .credit-card-types {
  display: flex !important;
  flex-direction: row;
  list-style: none;
  align-items: center; }
  .cs-checkout .payment-method #stripe_cc .credit-card-types li {
    padding: 0 5px;
    max-width: 48px;
    width: 100%;
    display: block;
    list-style: none;
    margin: 0; }
    .cs-checkout .payment-method #stripe_cc .credit-card-types li img {
      width: 100%;
      height: auto; }
