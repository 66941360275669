@import 'config/variables';
@import 'config/breakpoints';
@import 'vendors/include-media';

.cs-checkout {
    .payment-method {
        #stripe_cc {
            .payment-method-title{
                .label {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    img {
                        display: inline-block;
                    }
                }
            }

            .credit-card-types {
                display: flex !important;
                flex-direction: row;
                list-style: none;
                align-items: center;

                li{
                    padding: 0 5px;
                    max-width: 48px;
                    width: 100%;
                    display: block;
                    list-style: none;
                    margin: 0;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}
