@import 'config/variables';
@import 'config/breakpoints';
@import 'vendors/include-media';

$pagination_content-display: flex !default;
$pagination_content-flex-align-items: flex-start !default;

$pagination_prefix-label-margin: 0 !default;
$pagination_prefix-label-padding: 0 1rem 0 0 !default;

$pagination_page-provider-input-width: 4.5em !default;
$pagination_page-provider-input-font-size: 0.85em !default;

// prev/next arrows
$pagination_action-gutter: 1.5rem !default;
$pagination_action-icon-width: 0.5rem !default;
$pagination_action-icon-height: 2.4rem !default;
$pagination_action-icon-fill: $color_primary-500 !default;
$pagination_action-icon-transition: fill 0.15s linear !default;
$pagination-action--hover_icon-fill: $color_torea-bay !default;
$pagination-action--disabled_icon-fill: $color_alto !default;

.addthis-smartlayers .atss-left {
    display: none !important;
}

.page-layout-2columns-right {
    .columns {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .column.main {
        flex: 1;
    }

    .sidebar {
        max-width: 260px;
        width: 100%;
        flex-basis: 260px;
    }
    @include media('<=tablet') {
        .columns {
            flex-direction: column;
        }

        .column.main {
            flex: 1;
        }

        .post-list-content .pager {
            margin-bottom: 3rem;
        }

        .sidebar {
            width: 100%;
            flex-basis: 100%;
            flex: 1;
            max-width: 100%;
            padding-bottom: 4rem;
            padding-left: 0;
        }
    }
}



.mpblog-post-index {
    .cs-container__inner{
        > .page-title-wrapper {
            display: none;
        }
    }

    .cs-breadcrumbs {
        padding-top: 2.5rem;
        padding-bottom: 0;
    }
    @include media('<=tablet') {
        .columns {
            padding-top: 60px;
            position: relative;

        }
        .mpblog-search {
            position: absolute;
            top: 28px;
            left: 0;
            width: 100%;
        }
    }
}

.mpblog-post-view {
    @include media('<=tablet') {
        .columns {
            padding-top: 60px;
            position: relative;

        }
        .mpblog-search {
            position: absolute;
            top: 5px;
            left: 0;
            width: 100%;
        }
    }

    .post-view {
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
        margin-bottom: 15px;
        border-bottom: 1px solid $form_field-border;
    }

    .post-tags {
        align-self: flex-end;
    }

    .post-post_content a {
        color: $color_main__brand;
        text-decoration: underline;
        &:hover {
            color: $color_main__brand;
        }
    }
}

.post-list {
    &-wrapper {
        padding: 0;
    }

    &-item{
        border-bottom: 1px solid $form_field-border;
    }

    &-body {
        .post-list-item {
            padding: 0 0 2rem;
        }
    }
}

.mp-blog-rss {
    display: none;
}

#mpblog-list-container .mp-post-title a.post-link-title {
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 2.9rem;
    color: $color_black !important;
}

.mp-post-title {
    margin-bottom: 1rem;
    margin-top: 2rem;
}

.post-link-title {
    text-transform: capitalize;
}

.mp-read-more {
    color: $color_main__brand;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 25.89px;
    text-decoration: underline;
    &:hover {
        color: $color_black;
    }
    &:visited {
        color: $color_main__brand;
    }
}

.post-item {
    &-content {
        display: flex;
        flex-direction: row;

        margin-top: 22px;

        .post-image {
            min-width: 240px;
            max-width: 30%;
            width: 100%;
            margin-right: 20px;

            img {
                width: 100%;
                height: auto;
            }
        }

        .post-item-inner {
            flex: 1;
        }

        .mp-post-meta-info {
            margin-top: 15px;
        }

        .mp-post-info {
            text-align: right;
        }


        @include media('<laptop') {
            flex-direction: column;

            .post-image {
                min-width: 220px;
                max-width: 100%;
                width: 100%;
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
    }
}

.post-short-description {
    color: #3E3E3E;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 25.89px;
    margin-top: -4px;
}

.mp-post-info {
    color: #3E3E3E;
    font-size: 16px;
    display: none;
    letter-spacing: 0;
    line-height: 25.89px;
}

.post-date,
.post-author,
.post-tags {
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 1.5rem;
}

.post-date {
    background-image: url('../images/icons/calendar.svg');
    background-position: 1px 2px;
    margin-right: 20px;
    background-size: 14px;
    padding-left: 22px;
}
.post-author {
    background-image: url('../images/icons/user.svg');
    background-size: 15px;
    background-position: 1px 2px;
    padding-left: 25px;
}
.post-tags {
    background-image: url('../images/icons/tags.svg');
    background-size: 17px;
    background-position: 1px 3px;
    padding-left: 25px;
    text-align: right;
}

.post-list-content {
    .toolbar-amount {
        display: none !important;
    }

    .pager {
        margin: 4rem 0 6rem;
    }

    .pages-items {
        .label {
            display: none !important;
        }
    }

    .pages {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .view-all-link {
        font-size: 1.4rem;
        font-weight: bold;
        color: $color_black;
        text-decoration: none;
        line-height: 2.2rem;
        letter-spacing: 0.5px;
        border-bottom: 1px solid $color_black;
        margin-left: 2vw;
    }

    .pages-items {
        margin: 0;
        padding: 0;
        list-style: none;
        align-items: stretch;
        display: flex;
        align-items: flex-start;

        .item {
            margin: 0 6px 0 0;
            padding: 0;
            border: 2px solid $border_color;
            text-align: center;
            overflow: hidden;
            border-radius: 3px;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 3.5rem;
                min-height: 3.1rem;
                border: 0;
                margin: 0;
                padding: 0 0.3em;
                background: $color_white;
                cursor: pointer;
                line-height: 1;
                font-size: 1.4rem;
                font-weight: 600;
                transition: color 0.15s linear;

                span {
                    color: $color_black;
                }

                .ie11 & {
                    // Bug: Setting min-height breaks align-items: center in ie11.
                    // Setting height to a value less then min-height fixes the align-items: center issue in IE11, but the container still sets its size based on min-height
                    height: 1px;
                }

                @include media('<=phoneLg') {
                    &.previous,
                    &.next{
                        min-width: unset;
                        padding: 0;
                    }
                }
            }

            &.current {
                border: 2px solid $color_primary-500;
                strong {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 3.5rem;
                    min-height: 3.1rem;
                    border: 0;
                    margin: 0;
                    padding: 0 0.3em;
                    background: $outdoor_primary_dark;
                    color: $color_white;
                    cursor: pointer;
                    line-height: 1;
                    font-size: 1.4rem;
                    font-weight: 600;
                    transition: color 0.15s linear;
                }
            }

            &.pages-item-previous,
            &.pages-item-next {
                padding-left: 10px;
                padding-right: 10px;
                span {
                    text-transform: uppercase;
                    display: flex;
                    align-items: center;
                }
                @include media('<=phoneLg') {
                    padding: 0 16px;
                    span {
                        padding: 0;
                        font-size: 0;
                    }
                }
            }

            &.pages-item-previous {
                span {
                    position: relative;
                    padding-left: 15px;

                    svg {
                        position: absolute;
                        left: 0;
                    }
                }
                svg {
                    width: 0.5rem;
                    height: 2.4rem;
                    @include media('<=phoneLg') {
                        margin-right: 0;
                    }
                }
            }

            &.pages-item-next {
                @include media('<=phoneLg') {
                    margin-right: 0;
                }
                svg {
                    margin-left: 8px;
                    width: 0.5rem;
                    height: 2.4rem;
                    fill: $outdoor_primary_dark;
                    transition: fill 0.15s linear;
                    @include media('<=phoneLg') {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
/* Blog Search */

.mpblog-search {
    position: relative;
    border-radius: 20px;

    .label {
        display: none;
    }

    .field.search {
        margin: 0;
    }
    .field .input-text {
        border: 1px solid $form_field-border;
        border-radius: 20px;
        margin: 0;
        padding: 10px;
    }
    .actions {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 50px;

        button {
            font-size: 0;
            border: 0;
            border-bottom-right-radius: 20px;
            border-top-right-radius: 20px;
            background-color: $outdoor_primary_dark;
            background-image: url("../images/icons/search@1x.svg");
            background-size: 20px;
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
            background-position: 50%;
        }
    }
}

.mp-sidebar {
    &.mpcss,
    &.detailed {
        background: $color_light-gray;
        padding: 20px 15px;
        margin-top: 20px;
    }
    .block-title,
    .title {
        margin-bottom: 4px;
        p,
        span {
            font-size: 1.8rem;
            font-weight: bold;
            margin: 0;
        }
    }

    .list-post {
        a {
            color: $color_black;
            font-size: 1.4rem;
            text-decoration: underline;
        }
    }

    a.tags-list {
        display: block;
        color: $color_black;
        font-size: 16px !important;
        padding: 3px 0;
        position: relative;
        &:hover {
            background: transparent !important;
            color: $outdoor_primary_dark !important;
            &:before {
                content: '';
                display: block;
                position: absolute;
                background: $outdoor_primary_dark;
                left: -15px;
                width: 3px;
                height: 20px;
                top: 6px;
            }
        }
    }
}

.autocomplete-suggestions,
.autocomplete-no-suggestion {
    background: $color_white;
    border: 1px solid $color_light-gray;
    padding: 20px 15px;
    font-size: 1.4rem;

    .mpblog-product-line {
        padding: 1rem 0;
        font-size: 1.4rem;
        font-weight: bold;
        cursor: pointer;
    }
}

.mpblog-post-view {
    .page-title {
        color: $color_black;
        text-transform: capitalize;
        margin-bottom: 5px;
        margin-top: 0;
    }
    .mp-post-info {
        margin-right: 40px;
    }
    .post-view {
        margin-top: 15px;
        p {
            margin-bottom: 2.5rem;
        }
        img.left-align {
            margin: 0 20px 15px 0;
        }
        img.right-align {
            margin: 0 0 15px 20px;
        }
        img:not(.aligned) {
            width: 100%;
            height: auto;
        }

        @include media('<laptop') {
            img,
            img.left-align,
            img.right-align {
                width: 100%;
                height: auto;
                margin: 15px 0 20px;
            }
        }
    }
}

.addthis_toolbox,
.mp-blogpost-head,
#sharing {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}


.mp-blogpost-head {
    #sharing {
        .share-col-left {
            margin-right: 10px;
        }
    }

    .addthis_toolbox {
        a {
            display: block !important;
            margin-bottom: 0 !important;
            margin-right: 8px;
        }
    }

    @include media('<=phoneLg') {
        margin-top: 20px;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;

        .social-share {
            margin-top: 20px;
        }
    }
}

.addthis-smartlayer,
.at-share-dock-outer {
    display: none !important;
}
