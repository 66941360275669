/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.addthis-smartlayers .atss-left {
  display: none !important; }

.page-layout-2columns-right .columns {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; }

.page-layout-2columns-right .column.main {
  flex: 1; }

.page-layout-2columns-right .sidebar {
  max-width: 260px;
  width: 100%;
  flex-basis: 260px; }

@media (max-width: 48em) {
  .page-layout-2columns-right .columns {
    flex-direction: column; }
  .page-layout-2columns-right .column.main {
    flex: 1; }
  .page-layout-2columns-right .post-list-content .pager {
    margin-bottom: 3rem; }
  .page-layout-2columns-right .sidebar {
    width: 100%;
    flex-basis: 100%;
    flex: 1;
    max-width: 100%;
    padding-bottom: 4rem;
    padding-left: 0; } }

.mpblog-post-index .cs-container__inner > .page-title-wrapper {
  display: none; }

.mpblog-post-index .cs-breadcrumbs {
  padding-top: 2.5rem;
  padding-bottom: 0; }

@media (max-width: 48em) {
  .mpblog-post-index .columns {
    padding-top: 60px;
    position: relative; }
  .mpblog-post-index .mpblog-search {
    position: absolute;
    top: 28px;
    left: 0;
    width: 100%; } }

@media (max-width: 48em) {
  .mpblog-post-view .columns {
    padding-top: 60px;
    position: relative; }
  .mpblog-post-view .mpblog-search {
    position: absolute;
    top: 5px;
    left: 0;
    width: 100%; } }

.mpblog-post-view .post-view {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #B6BEC5; }

.mpblog-post-view .post-tags {
  align-self: flex-end; }

.mpblog-post-view .post-post_content a {
  color: #FF4604;
  text-decoration: underline; }
  .mpblog-post-view .post-post_content a:hover {
    color: #FF4604; }

.post-list-wrapper {
  padding: 0; }

.post-list-item {
  border-bottom: 1px solid #B6BEC5; }

.post-list-body .post-list-item {
  padding: 0 0 2rem; }

.mp-blog-rss {
  display: none; }

#mpblog-list-container .mp-post-title a.post-link-title {
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 2.9rem;
  color: #000 !important; }

.mp-post-title {
  margin-bottom: 1rem;
  margin-top: 2rem; }

.post-link-title {
  text-transform: capitalize; }

.mp-read-more {
  color: #FF4604;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25.89px;
  text-decoration: underline; }
  .mp-read-more:hover {
    color: #000; }
  .mp-read-more:visited {
    color: #FF4604; }

.post-item-content {
  display: flex;
  flex-direction: row;
  margin-top: 22px; }
  .post-item-content .post-image {
    min-width: 240px;
    max-width: 30%;
    width: 100%;
    margin-right: 20px; }
    .post-item-content .post-image img {
      width: 100%;
      height: auto; }
  .post-item-content .post-item-inner {
    flex: 1; }
  .post-item-content .mp-post-meta-info {
    margin-top: 15px; }
  .post-item-content .mp-post-info {
    text-align: right; }
  @media (max-width: 63.99em) {
    .post-item-content {
      flex-direction: column; }
      .post-item-content .post-image {
        min-width: 220px;
        max-width: 100%;
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px; } }

.post-short-description {
  color: #3E3E3E;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25.89px;
  margin-top: -4px; }

.mp-post-info {
  color: #3E3E3E;
  font-size: 16px;
  display: none;
  letter-spacing: 0;
  line-height: 25.89px; }

.post-date,
.post-author,
.post-tags {
  background-repeat: no-repeat;
  background-size: contain;
  font-size: 1.5rem; }

.post-date {
  background-image: url("../images/icons/calendar.svg");
  background-position: 1px 2px;
  margin-right: 20px;
  background-size: 14px;
  padding-left: 22px; }

.post-author {
  background-image: url("../images/icons/user.svg");
  background-size: 15px;
  background-position: 1px 2px;
  padding-left: 25px; }

.post-tags {
  background-image: url("../images/icons/tags.svg");
  background-size: 17px;
  background-position: 1px 3px;
  padding-left: 25px;
  text-align: right; }

.post-list-content .toolbar-amount {
  display: none !important; }

.post-list-content .pager {
  margin: 4rem 0 6rem; }

.post-list-content .pages-items .label {
  display: none !important; }

.post-list-content .pages {
  display: flex;
  flex-direction: row;
  align-items: center; }

.post-list-content .view-all-link {
  font-size: 1.4rem;
  font-weight: bold;
  color: #000;
  text-decoration: none;
  line-height: 2.2rem;
  letter-spacing: 0.5px;
  border-bottom: 1px solid #000;
  margin-left: 2vw; }

.post-list-content .pages-items {
  margin: 0;
  padding: 0;
  list-style: none;
  align-items: stretch;
  display: flex;
  align-items: flex-start; }
  .post-list-content .pages-items .item {
    margin: 0 6px 0 0;
    padding: 0;
    border: 2px solid #EFEFF0;
    text-align: center;
    overflow: hidden;
    border-radius: 3px; }
    .post-list-content .pages-items .item a {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 3.5rem;
      min-height: 3.1rem;
      border: 0;
      margin: 0;
      padding: 0 0.3em;
      background: #ffffff;
      cursor: pointer;
      line-height: 1;
      font-size: 1.4rem;
      font-weight: 600;
      transition: color 0.15s linear; }
      .post-list-content .pages-items .item a span {
        color: #000; }
      .ie11 .post-list-content .pages-items .item a {
        height: 1px; }
      @media (max-width: 40em) {
        .post-list-content .pages-items .item a.previous, .post-list-content .pages-items .item a.next {
          min-width: unset;
          padding: 0; } }
    .post-list-content .pages-items .item.current {
      border: 2px solid #FF4604; }
      .post-list-content .pages-items .item.current strong {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 3.5rem;
        min-height: 3.1rem;
        border: 0;
        margin: 0;
        padding: 0 0.3em;
        background: #FF4604;
        color: #ffffff;
        cursor: pointer;
        line-height: 1;
        font-size: 1.4rem;
        font-weight: 600;
        transition: color 0.15s linear; }
    .post-list-content .pages-items .item.pages-item-previous, .post-list-content .pages-items .item.pages-item-next {
      padding-left: 10px;
      padding-right: 10px; }
      .post-list-content .pages-items .item.pages-item-previous span, .post-list-content .pages-items .item.pages-item-next span {
        text-transform: uppercase;
        display: flex;
        align-items: center; }
      @media (max-width: 40em) {
        .post-list-content .pages-items .item.pages-item-previous, .post-list-content .pages-items .item.pages-item-next {
          padding: 0 16px; }
          .post-list-content .pages-items .item.pages-item-previous span, .post-list-content .pages-items .item.pages-item-next span {
            padding: 0;
            font-size: 0; } }
    .post-list-content .pages-items .item.pages-item-previous span {
      position: relative;
      padding-left: 15px; }
      .post-list-content .pages-items .item.pages-item-previous span svg {
        position: absolute;
        left: 0; }
    .post-list-content .pages-items .item.pages-item-previous svg {
      width: 0.5rem;
      height: 2.4rem; }
      @media (max-width: 40em) {
        .post-list-content .pages-items .item.pages-item-previous svg {
          margin-right: 0; } }
    @media (max-width: 40em) {
      .post-list-content .pages-items .item.pages-item-next {
        margin-right: 0; } }
    .post-list-content .pages-items .item.pages-item-next svg {
      margin-left: 8px;
      width: 0.5rem;
      height: 2.4rem;
      fill: #FF4604;
      transition: fill 0.15s linear; }
      @media (max-width: 40em) {
        .post-list-content .pages-items .item.pages-item-next svg {
          margin-left: 0; } }

/* Blog Search */
.mpblog-search {
  position: relative;
  border-radius: 20px; }
  .mpblog-search .label {
    display: none; }
  .mpblog-search .field.search {
    margin: 0; }
  .mpblog-search .field .input-text {
    border: 1px solid #B6BEC5;
    border-radius: 20px;
    margin: 0;
    padding: 10px; }
  .mpblog-search .actions {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px; }
    .mpblog-search .actions button {
      font-size: 0;
      border: 0;
      border-bottom-right-radius: 20px;
      border-top-right-radius: 20px;
      background-color: #FF4604;
      background-image: url("../images/icons/search@1x.svg");
      background-size: 20px;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      background-position: 50%; }

.mp-sidebar.mpcss, .mp-sidebar.detailed {
  background: #F4F4F4;
  padding: 20px 15px;
  margin-top: 20px; }

.mp-sidebar .block-title,
.mp-sidebar .title {
  margin-bottom: 4px; }
  .mp-sidebar .block-title p,
  .mp-sidebar .block-title span,
  .mp-sidebar .title p,
  .mp-sidebar .title span {
    font-size: 1.8rem;
    font-weight: bold;
    margin: 0; }

.mp-sidebar .list-post a {
  color: #000;
  font-size: 1.4rem;
  text-decoration: underline; }

.mp-sidebar a.tags-list {
  display: block;
  color: #000;
  font-size: 16px !important;
  padding: 3px 0;
  position: relative; }
  .mp-sidebar a.tags-list:hover {
    background: transparent !important;
    color: #FF4604 !important; }
    .mp-sidebar a.tags-list:hover:before {
      content: '';
      display: block;
      position: absolute;
      background: #FF4604;
      left: -15px;
      width: 3px;
      height: 20px;
      top: 6px; }

.autocomplete-suggestions,
.autocomplete-no-suggestion {
  background: #ffffff;
  border: 1px solid #F4F4F4;
  padding: 20px 15px;
  font-size: 1.4rem; }
  .autocomplete-suggestions .mpblog-product-line,
  .autocomplete-no-suggestion .mpblog-product-line {
    padding: 1rem 0;
    font-size: 1.4rem;
    font-weight: bold;
    cursor: pointer; }

.mpblog-post-view .page-title {
  color: #000;
  text-transform: capitalize;
  margin-bottom: 5px;
  margin-top: 0; }

.mpblog-post-view .mp-post-info {
  margin-right: 40px; }

.mpblog-post-view .post-view {
  margin-top: 15px; }
  .mpblog-post-view .post-view p {
    margin-bottom: 2.5rem; }
  .mpblog-post-view .post-view img.left-align {
    margin: 0 20px 15px 0; }
  .mpblog-post-view .post-view img.right-align {
    margin: 0 0 15px 20px; }
  .mpblog-post-view .post-view img:not(.aligned) {
    width: 100%;
    height: auto; }
  @media (max-width: 63.99em) {
    .mpblog-post-view .post-view img,
    .mpblog-post-view .post-view img.left-align,
    .mpblog-post-view .post-view img.right-align {
      width: 100%;
      height: auto;
      margin: 15px 0 20px; } }

.addthis_toolbox,
.mp-blogpost-head,
#sharing {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center; }

.mp-blogpost-head #sharing .share-col-left {
  margin-right: 10px; }

.mp-blogpost-head .addthis_toolbox a {
  display: block !important;
  margin-bottom: 0 !important;
  margin-right: 8px; }

@media (max-width: 40em) {
  .mp-blogpost-head {
    margin-top: 20px;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start; }
    .mp-blogpost-head .social-share {
      margin-top: 20px; } }

.addthis-smartlayer,
.at-share-dock-outer {
  display: none !important; }
